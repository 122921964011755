import Button, { Kind, Type, Size } from 'components/Button';
import H1 from 'components/H1';
import useConfig from 'domains/config/useConfig';
import { getComponentUuid, getClass } from 'helpers/components';
import useFetch from 'hooks/useFetch';
import React, {
  ReactElement,
  useState,
  useRef,
  useEffect,
  FunctionComponent,
} from 'react';
import { connect } from 'react-redux';
import { Index as Routes } from 'routes';
import * as adminActions from 'store/actions/admin';
import * as adminSelectors from 'store/selectors/admin';
import FetchMethod from 'types/fetchMethod';
import State from 'types/state';
import { fetchApi } from 'helpers/fetching';
import { FeatureFlagsForm } from './components/FeatureFlagsForm';
import ImportReport from './components/ImportReport';

export const toolsListComponentName = 'tools-list';
export const toolsListComponent = getComponentUuid(toolsListComponentName);

const LOCALE_TOOLS_LIST_TITLE = 'Tools';
const LOCALE_TOOLS_LAUNCH_DOCS = 'Launch AAPI Docs';
const LOCALE_TOOLS_DOWNLOAD_USERS_REPORT = 'Download Users report';
const LOCALE_TOOLS_IS_DOWNLOADING_USERS_REPORT = 'Downloading...';
const LOCALE_TOOLS_GENERATE_TOKEN = 'Generate Token';

type Props = {
  loadUsersReport: () => Promise<void>;
  isLoadingUserReport: boolean;
};

const ToolsList: FunctionComponent<Props> = (props): ReactElement => {
  const { loadUsersReport, isLoadingUserReport } = props;
  const [swaggerToken, setSwaggerToken] = useState('');
  const tokenRef = useRef<HTMLSpanElement>(null);
  const { config } = useConfig();
  const { doFetch } = useFetch<{ token: string }>();

  /** Copies the authentication token to the browser clipboard */
  const copyToClipboard = (): void => {
    // Create a range and use it to select the token text
    const range = document.createRange();
    if (tokenRef.current) {
      range.selectNode(tokenRef.current);
    }
    window.getSelection()?.addRange(range);

    // Attempt to execute the copy command
    try {
      const success = document.execCommand('copy');
      const message = success ? 'Copy was successful' : 'Copy was unsuccessful';
      console.info(message);
    } catch (error) {
      console.error('Unable to copy text to clipboard', error);
    }

    window.getSelection()?.removeAllRanges();
  };

  /** Effect hook that copies token to clipboard after retrieval */
  useEffect(() => {
    if (swaggerToken) {
      copyToClipboard();

      // Clear the token after 10 seconds
      setTimeout(() => {
        setSwaggerToken('');
      }, 1000 * 10);
    }
  }, [swaggerToken]);

  const toolsListClass = getClass(toolsListComponentName);

  /** Retrieves the authentication token from the AAPI
   * for use with Swagger */
  const retrieveAuthToken = (): void => {
    // Clear out the token when requesting a new one
    if (swaggerToken) {
      setSwaggerToken('');
    }

    // Fetch and set the token into state
    doFetch({
      endpoint: `/${Routes.SEGMENT_UI_CONFIG}/token`,
      method: FetchMethod.GET,
      payload: {},
      onSuccess: (res) => setSwaggerToken(res[0].token),
    });
  };

  /** Opens the AAPI Swagger site into a new tab */
  const launchDocumentSite = async (): Promise<void> => {
    try {
      const response = await fetchApi({
        endpoint: `${Routes.SEGMENT_ADMIN}/aapi_docs`,
        method: FetchMethod.GET,
      });

      const papiURL = response.data[0];

      if (papiURL) {
        window.open(`${papiURL}/docs/`, '_blank');
      } else {
        console.error('papiURL is not available in the response.');
      }
    } catch (error) {
      console.error('Failed to fetch the API documentation URL:', error);
    }
  };

  return (
    <div className={toolsListClass}>
      <div className="container-fluid">
        <div className="header-row row mb-1">
          <H1>{LOCALE_TOOLS_LIST_TITLE}</H1>
        </div>

        <div className="row auth-box align-items-center flex-nowrap mb-1">
          <div className="col-auto pl-0">
            <Button
              kind={Kind.primary}
              type={Type.button}
              size={Size.medium}
              onClick={retrieveAuthToken}
              className={`${toolsListClass} spaced-btn`}
            >
              {LOCALE_TOOLS_GENERATE_TOKEN}
            </Button>
          </div>

          {/** Display the Swagger token when its available */}
          {swaggerToken && (
            <div className="col scroll-box">
              <div className="row">
                <div className="col-auto pl-0">
                  <span ref={tokenRef}>{swaggerToken}</span>
                </div>
                <div className="col-auto pl-0">
                  <span className={`${toolsListClass}-token`}>
                    Copied to clipboard!
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="row mb-1">
          <Button
            kind={Kind.primary}
            type={Type.button}
            size={Size.medium}
            onClick={() => launchDocumentSite()}
            className={`${toolsListClass} spaced-btn`}
          >
            {LOCALE_TOOLS_LAUNCH_DOCS}
          </Button>
        </div>

        <div className="row mb-1">
          <Button
            kind={Kind.primary}
            type={Type.button}
            size={Size.medium}
            onClick={loadUsersReport}
            className={`${toolsListClass} spaced-btn`}
            disabled={isLoadingUserReport}
          >
            {isLoadingUserReport
              ? LOCALE_TOOLS_IS_DOWNLOADING_USERS_REPORT
              : LOCALE_TOOLS_DOWNLOAD_USERS_REPORT}
          </Button>
        </div>
      </div>

      <br />
      <br />

      <ImportReport />
      <FeatureFlagsForm />
    </div>
  );
};

const mapStateToProps = (state: State): { isLoadingUserReport: boolean } => ({
  isLoadingUserReport: adminSelectors.isLoadingUserReport(state),
});

const mapDispatchToProps = {
  loadUsersReport: adminActions.loadUsersReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToolsList);
